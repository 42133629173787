import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import img1 from './assets/img1.jpeg';
import img2 from './assets/img2.jpeg';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { httpsCallable } from 'firebase/functions';
import { functions, storage } from './firebaseHelper';
import { ref, uploadBytes } from 'firebase/storage';
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Radio, RadioGroup } from '@mui/material';
import { Check, Close, Delete, Edit, Send } from '@mui/icons-material';
import swal from 'sweetalert';

function App() {
  const sigCanvasRef = useRef();
  const [signature, setSignature] = useState(null); // Almacena la imagen de la firma
  const [isSigning, setIsSigning] = useState(false); // Controla la visualización del área de firma

  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();

  function convertCanvasToBlackAndWhite(canvas) {
    let ctx = canvas.getContext('2d');
    let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let pixels = imgData.data;

    for (let i = 0; i < pixels.length; i += 4) {
      let lightness = parseInt((pixels[i] + pixels[i + 1] + pixels[i + 2]) / 3);

      pixels[i] = lightness;     // rojo
      pixels[i + 1] = lightness; // verde
      pixels[i + 2] = lightness; // azul
      // alfa no se modifica
    }

    ctx.putImageData(imgData, 0, 0);
  }

  const generatePDF = async () => {
    try {
      setSending(true);

      // wait 100 then scroll to the bottom
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);

      const pdf = new jsPDF('p', 'mm', 'a4');
      const refs = [ref1, ref2, ref3];

      for (let i = 0; i < refs.length; i++) {
        const canvas = await html2canvas(refs[i].current, { scale: 2 });

        // Convertir el canvas a blanco y negro
        convertCanvasToBlackAndWhite(canvas);

        const imgData = canvas.toDataURL('image/png');

        if (i > 0) {
          pdf.addPage(); // Añade una nueva página después de la primera
        }

        // Establece el color de fondo del PDF
        pdf.setFillColor(242, 243, 243); // RGB para #F2F3F3
        pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(), 'F'); // Dibuja un rectángulo lleno como fondo

        // Ajusta la imagen al tamaño de la página
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfRatio = pdfWidth / pdfHeight;
        const imgRatio = imgProps.width / imgProps.height;
        let finalWidth, finalHeight;

        if (imgRatio >= pdfRatio) {
          finalWidth = pdfWidth;
          finalHeight = (imgProps.height * pdfWidth) / imgProps.width;
        } else {
          finalHeight = pdfHeight;
          finalWidth = (imgProps.width * pdfHeight) / imgProps.height;
        }

        // Centra la imagen en el PDF
        const startX = (pdfWidth - finalWidth) / 2;
        const startY = (pdfHeight - finalHeight) / 2;
        pdf.addImage(imgData, 'PNG', startX, startY, finalWidth, finalHeight);

      }

      const sendPdfByEmail = httpsCallable(functions, 'sendPdfByEmail2');

      // guardar pdf en firestore
      const storageRef = ref(storage, 'pdfs/' + Date.now() + '-archivo.pdf');
      const pdfBlob = pdf.output('blob');
      await uploadBytes(storageRef, pdfBlob);

      const result = await sendPdfByEmail({ email: 'mlin.ttos23@gmail.com', ruta: storageRef.fullPath, nombre: name });

      // mlin.ttos23@gmail.com

      console.log(result.data.result);
      swal({
        title: "¡Éxito!",
        text: "El formulario ha sido enviado correctamente.",
        // title: "El formulario ha sido enviado correctamente.",
        icon: "success",
        showOkButton: false,
        showConfirmButton: false,
        buttons: false, // Esto es para no mostrar ningún botón
        timer: 3000
      });
    } catch (error) {
      console.error('Error en generatePDF:', error);
    } finally {
      setSending(false);
    }
  };


  // Limpiar el área de firma
  const clear = () => sigCanvasRef.current.clear();

  // Guardar la firma como imagen y volver al estado inicial
  const save = () => {
    const image = sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
    setSignature(image); // Guarda la imagen de la firma
    setIsSigning(false); // Vuelve al estado inicial
    // wait 100 then scroll to the bottom
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 100);
  };

  // Muestra el área de firma
  const startSigning = () => {
    setIsSigning(true);
    setSignature(null); // Limpia la firma previa
  };

  const [done, setDone] = useState(false); // Controla si el formulario ha sido completado
  const [sending, setSending] = useState(false); // Controla si el formulario está siendo enviado
  const [name, setName] = useState(''); // Almacena el nombre del firmante
  const [fecha, setFecha] = useState(new Date().toISOString().split('T')[0]); // Almacena la fecha de firma
  const [fechaNacimiento, setFechaNacimiento] = useState(''); // Almacena la fecha de nacimiento del firmante
  const [edad, setEdad] = useState(''); // Almacena la edad del firmante
  const [comoTeEnteraste, setComoTeEnteraste] = useState(''); // Almacena la respuesta a la pregunta "¿Cómo te enteraste de nosotros?"

  const [sensibleAlDolor, setSensibleAlDolor] = useState(''); // Almacena la respuesta a la pregunta "¿Es usted sensible al dolor?"
  const [fumasObebes, setFumasObebes] = useState(''); // Almacena la respuesta a la pregunta "¿Fumas o bebes alcohol?"
  const [alergiasMedicamento, setAlergiasMedicamento] = useState(''); // Almacena la respuesta a la pregunta "¿Alergias a algún medicamento o anestesia?"
  const [alergiasCosmeticos, setAlergiasCosmeticos] = useState(''); // Almacena la respuesta a la pregunta "¿Alergias a algún cosmético?"
  const [cuidadosMedicos, setCuidadosMedicos] = useState(''); // Almacena la respuesta a la pregunta "¿Se encuentra bajo cuidados médicos?"
  const [medicamentos, setMedicamentos] = useState(''); // Almacena la respuesta a la pregunta "¿Toma algún medicamento periódicamente?"
  const [aspirina, setAspirina] = useState(''); // Almacena la respuesta a la pregunta "¿Consume aspirina o anticuagulantes?"
  const [herpesSimplex, setHerpesSimplex] = useState(''); // Almacena la respuesta a la pregunta "¿Padece Herpes Simplex (Fuego labial)?"

  const [alergiasMedicamentoCual, setAlergiasMedicamentoCual] = useState('');
  const [alergiasCosmeticosCual, setAlergiasCosmeticosCual] = useState('');
  const [cuidadosMedicosCual, setCuidadosMedicosCual] = useState('');
  const [medicamentosCual, setMedicamentosCual] = useState('');
  const [aspirinaCual, setAspirinaCual] = useState('');

  const listaCondiciones = [
    "Cáncer", "Hepatitis", "Tiroides", "Problemas cardiacos", "Osteoporosis",
    "Embarazo o lactancia", "Diabetes", "Marcapasos", "Tromboflebitis",
    "Cicatrización Queloide", "Dermatitis", "Enfermedades Renales", "VIH(SIDA)",
    "Hipertensión", "Colesterol", "Inmunodeficiente", "Psoriasis", "Acné Severo",
    "Trastornos hormonales", "Vitiligo", "Hemofilia", "Lupus"
  ];

  const [condiciones, setCondiciones] = useState([]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth - 20);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth - 20);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setScreenWidth(window.innerWidth - 20);
      });
    };
  }, []);

  function calcularEdad(fechaNacimiento) {
    const hoy = new Date();
    const nacimiento = new Date(fechaNacimiento);
    let edad = hoy.getFullYear() - nacimiento.getFullYear();
    const diferenciaMeses = hoy.getMonth() - nacimiento.getMonth();
    if (diferenciaMeses < 0 || (diferenciaMeses === 0 && hoy.getDate() < nacimiento.getDate())) {
      edad--;
    }
    return edad;
  }

  return (
    <div className="bg-eggshell flex flex-col items-center justify-center w-full">
      {isSigning ? (
        // Área de firma
        <div className="h-screen flex flex-col items-center justify-center w-full">
          <p className="text-2xl font-playfair text-customblack mb-4">
            F I R M A
          </p>
          <SignatureCanvas
            ref={sigCanvasRef}
            penColor='black'
            // minWidth={3} // Ancho mínimo más grueso
            // maxWidth={6} // Ancho máximo más grueso
            canvasProps={{ width: screenWidth > 800 ? 800 : screenWidth, height: 300, className: 'sigCanvas', style: { border: '3px dashed gray', borderRadius: '5px' } }}
          />
          <div className="mt-4 flex flex-row items-center justify-center space-x-4">
            <button onClick={clear} className="font-playfair font-bold mb-4 flex flex-row items-center justify-center border-2 border-customblack px-2 py-1 rounded-md hover:bg-egghselldarker">
              <Close className="mr-2" />
              Limpiar
            </button>
            <button onClick={save} className="font-playfair font-bold mb-4 flex flex-row items-center justify-center border-2 border-customblack px-2 py-1 rounded-md hover:bg-egghselldarker">
              <Check className="mr-2" />
              Guardar
            </button>
          </div>
        </div>
      ) : (
        // Vista inicial o de firma guardada
        <>
          <img src={img1} className="h-screen object-contain" ref={ref1} />

          <div ref={ref2} className="flex flex-col items-center justify-center w-full bg-eggshell">
            <p className="text-2xl font-playfair text-customblack">
              F O R M U L A R I O&nbsp;&nbsp;D E
            </p>
            <p className="text-2xl mb-4 font-playfair text-customblack">
              A D M I S I Ó N
            </p>
            <p className="text-2xsm sm:text-sm mb-2 font-utopia flex flex-row items-center justify-between whitespace-nowrap w-full sm:w-110 px-4 sm:px-0">
              <span className="flex flex-row items-center w-3/5 h-4 bg-eggshell">
                N O M B R E:
                {!done ?
                  <input
                    type="text"
                    className="border w-full h-4 ml-2 bg-transparent border-b-1 border-customblack border-t-0 border-l-0 border-r-0 rounded-none focus:outline-none"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  /> :
                  <span className="w-full ml-2 font-bold font-playfair mb-1">{name}</span>
                }
              </span>

              <span className="flex flex-row items-center w-2/5 h-4 bg-eggshell ml-2">
                F E C H A:
                {!done ?
                  <input
                    type="date"
                    className="border w-full h-4 ml-2 bg-transparent border-b-1 border-customblack border-t-0 border-l-0 border-r-0 rounded-none focus:outline-none"
                    value={fecha}
                    onChange={(e) => setFecha(e.target.value)}
                  /> :
                  <span className="w-full ml-2 font-bold font-playfair mb-1">{fecha}</span>
                }
              </span>
            </p>
            <p className="text-2xsm sm:text-sm mb-2 font-utopia flex flex-row items-center justify-between whitespace-nowrap w-full sm:w-110 px-4 sm:px-0">
              <span className="flex flex-row items-center w-4/5">
                F E C H A&nbsp;&nbsp;D E&nbsp;&nbsp;N A C I M I E N T O:
                {!done ?
                  <input
                    type="date"
                    className="border w-full h-4 ml-2 bg-transparent border-b-1 border-customblack border-t-0 border-l-0 border-r-0 rounded-none focus:outline-none"
                    value={fechaNacimiento}
                    onChange={(e) => {
                      const nuevaFecha = e.target.value;
                      setFechaNacimiento(nuevaFecha);
                      const edadCalculada = calcularEdad(nuevaFecha);
                      setEdad(edadCalculada);
                    }}
                  />
                  :
                  <span className="w-full ml-2 font-bold font-playfair mb-1">
                    {fechaNacimiento}
                  </span>
                }
              </span>
              <span className="flex flex-row items-center w-1/5 ml-2">
                E D A D:
                {!done ? (
                  <input
                    type="number"
                    className="border w-full h-4 ml-2 bg-transparent border-b-1 border-customblack border-t-0 border-l-0 border-r-0 rounded-none focus:outline-none"
                    value={edad}
                    onChange={(e) => setEdad(e.target.value)}
                  />
                ) : (
                  <span className="w-full ml-2 font-bold font-playfair mb-1">{edad}</span>
                )}
              </span>
            </p>
            <p className="text-2xsm sm:text-sm mb-2 font-utopia w-full sm:w-110 px-4 sm:px-0 flex flex-row items-center whitespace-nowrap">
              ¿ C Ó M O&nbsp;&nbsp;T E&nbsp;&nbsp;E N T E R A S T E&nbsp;&nbsp;D E&nbsp;&nbsp;N O S O T R O S ?
              {!done ?
                <input
                  type="text"
                  className="border w-full h-4 ml-2 bg-transparent border-b-1 border-customblack border-t-0 border-l-0 border-r-0 rounded-none focus:outline-none"
                  value={comoTeEnteraste}
                  onChange={(e) => setComoTeEnteraste(e.target.value)}
                /> :
                <span className="w-full ml-2 font-bold font-playfair mb-1">{comoTeEnteraste}</span>
              }
            </p>

            <p className="text-2xl font-playfair text-customblack mt-8">
              C U E S T I O N A R I O&nbsp;&nbsp;D E
            </p>
            <p className="text-2xl mb-4 font-playfair text-customblack">
              S A L U D
            </p>
            <div className="text-2xsm sm:text-sm mb-2 font-utopia w-full sm:w-120 px-4 sm:px-0 flex flex-row items-center whitespace-nowrap justify-between">
              ¿ E s&nbsp;&nbsp;u s t e d&nbsp;&nbsp; s e n s i b l e&nbsp;&nbsp; a l&nbsp;&nbsp; d o l o r ?
              {!done ?
                <RadioGroup
                  row
                  aria-label="dolor"
                  name="row-radio-buttons-group"
                  value={sensibleAlDolor}
                  onChange={(event) => setSensibleAlDolor(event.target.value)}
                >
                  <FormControlLabel
                    value="si"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="Sí"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="No"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                </RadioGroup> :
                sensibleAlDolor === "si" ?
                  < p className='font-bold font-playfair'>
                    Sí
                  </p> : sensibleAlDolor === "no" ?
                    <p className='font-bold font-playfair'>
                      No
                    </p> :
                    <p className='font-bold font-playfair'>
                      ?
                    </p>
              }
            </div>
            <div className="text-2xsm sm:text-sm mb-2 font-utopia w-full sm:w-120 px-4 sm:px-0 flex flex-row items-center whitespace-nowrap justify-between">
              ¿ F u m a s&nbsp;&nbsp;o&nbsp;&nbsp;b e b e s&nbsp;&nbsp; a l c o h o l ?
              {!done ?
                <RadioGroup
                  row
                  aria-label="fumar"
                  name="row-radio-buttons-group"
                  value={fumasObebes}
                  onChange={(event) => setFumasObebes(event.target.value)}
                >
                  <FormControlLabel
                    value="si"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="Sí"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="No"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                </RadioGroup> :
                fumasObebes === "si" ?
                  < p className='font-bold font-playfair'>
                    Sí
                  </p> : fumasObebes === "no" ?
                    <p className='font-bold font-playfair'>
                      No
                    </p> :
                    <p className='font-bold font-playfair'>
                      ?
                    </p>
              }
            </div>
            <div className="text-2xsm sm:text-sm mb-2 font-utopia w-full sm:w-120 px-4 sm:px-0 flex flex-row items-center whitespace-nowrap justify-between">
              ¿ A l e r g i a s&nbsp;&nbsp;a&nbsp;&nbsp; a l g ú n&nbsp;&nbsp; m e d i c a m e n t o&nbsp;&nbsp;o&nbsp;&nbsp; a n e s t e s i a ?
              {!done ?
                <RadioGroup
                  row
                  aria-label="alergiasMedicamento"
                  name="row-radio-buttons-group"
                  value={alergiasMedicamento}
                  onChange={(event) => setAlergiasMedicamento(event.target.value)}
                >
                  <FormControlLabel
                    value="si"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="Sí"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="No"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                </RadioGroup> :
                alergiasMedicamento === "si" ?
                  < p className='font-bold font-playfair'>
                    Sí
                  </p> : alergiasMedicamento === "no" ?
                    <p className='font-bold font-playfair'>
                      No
                    </p> :
                    <p className='font-bold font-playfair'>
                      ?
                    </p>
              }
            </div>
            {alergiasMedicamento === "si" &&
              <div className="text-2xsm sm:text-sm mb-2 font-utopia w-full sm:w-120 px-4 sm:px-0 flex flex-row items-center whitespace-nowrap justify-between">
                ¿ C u á l ?
                {!done ?
                  <input
                    type="text"
                    className="border w-full h-4 ml-2 bg-transparent border-b-1 border-customblack border-t-0 border-l-0 border-r-0 rounded-none focus:outline-none"
                    value={alergiasMedicamentoCual}
                    onChange={(e) => setAlergiasMedicamentoCual(e.target.value)}
                  />
                  :
                  <span className="w-full ml-2 font-bold font-playfair mb-1">
                    {alergiasMedicamentoCual}
                  </span>
                }
              </div>
            }
            <div className="text-2xsm sm:text-sm mb-2 font-utopia w-full sm:w-120 px-4 sm:px-0 flex flex-row items-center whitespace-nowrap justify-between">
              ¿ A l e r g i a s&nbsp;&nbsp;a&nbsp;&nbsp; a l g ú n&nbsp;&nbsp; c o s m é t i c o ?
              {!done ?
                <RadioGroup
                  row
                  aria-label="alergiasCosmeticos"
                  name="row-radio-buttons-group"
                  value={alergiasCosmeticos}
                  onChange={(event) => setAlergiasCosmeticos(event.target.value)}
                >
                  <FormControlLabel
                    value="si"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="Sí"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="No"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                </RadioGroup> :
                alergiasCosmeticos === "si" ?
                  < p className='font-bold font-playfair'>
                    Sí
                  </p> : alergiasCosmeticos === "no" ?
                    <p className='font-bold font-playfair'>
                      No
                    </p> :
                    <p className='font-bold font-playfair'>
                      ?
                    </p>
              }
            </div>

            {alergiasCosmeticos === "si" &&
              <div className="text-2xsm sm:text-sm mb-2 font-utopia w-full sm:w-120 px-4 sm:px-0 flex flex-row items-center whitespace-nowrap justify-between">
                ¿ C u á l ?
                {!done ?
                  <input
                    type="text"
                    className="border w-full h-4 ml-2 bg-transparent border-b-1 border-customblack border-t-0 border-l-0 border-r-0 rounded-none focus:outline-none"
                    value={alergiasCosmeticosCual}
                    onChange={(e) => setAlergiasCosmeticosCual(e.target.value)}
                  />
                  :
                  <span className="w-full ml-2 font-bold font-playfair mb-1">
                    {alergiasCosmeticosCual}
                  </span>
                }
              </div>
            }

            <div className="text-2xsm sm:text-sm mb-2 font-utopia w-full sm:w-120 px-4 sm:px-0 flex flex-row items-center whitespace-nowrap justify-between">
              ¿ S e&nbsp;&nbsp;e n c u e n t r a&nbsp;&nbsp;b a j o&nbsp;&nbsp; c u i d a d o s&nbsp;&nbsp; m é d i c o s ?
              {!done ?
                <RadioGroup
                  row
                  aria-label="cuidadosMedicos"
                  name="row-radio-buttons-group"
                  value={cuidadosMedicos}
                  onChange={(event) => setCuidadosMedicos(event.target.value)}
                >
                  <FormControlLabel
                    value="si"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="Sí"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="No"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                </RadioGroup> :
                cuidadosMedicos === "si" ?
                  < p className='font-bold font-playfair'>
                    Sí
                  </p> : cuidadosMedicos === "no" ?
                    <p className='font-bold font-playfair'>
                      No
                    </p> :
                    <p className='font-bold font-playfair'>
                      ?
                    </p>
              }
            </div>
            {cuidadosMedicos === "si" &&
              <div className="text-2xsm sm:text-sm mb-2 font-utopia w-full sm:w-120 px-4 sm:px-0 flex flex-row items-center whitespace-nowrap justify-between">
                ¿ C u á l e s ?
                {!done ?
                  <input
                    type="text"
                    className="border w-full h-4 ml-2 bg-transparent border-b-1 border-customblack border-t-0 border-l-0 border-r-0 rounded-none focus:outline-none"
                    value={cuidadosMedicosCual}
                    onChange={(e) => setCuidadosMedicosCual(e.target.value)}
                  /> :
                  <span className="w-full ml-2 font-bold font-playfair mb-1">
                    {cuidadosMedicosCual}
                  </span>
                }
              </div>
            }
            <div className="text-2xsm sm:text-sm mb-2 font-utopia w-full sm:w-120 px-4 sm:px-0 flex flex-row items-center whitespace-nowrap justify-between">
              ¿ T o m a&nbsp;&nbsp;a l g ú n&nbsp;&nbsp; m e d i c a m e n t o&nbsp;&nbsp; p e r i ó d i c a m e n t e ?
              {!done ?
                <RadioGroup
                  row
                  aria-label="medicamentos"
                  name="row-radio-buttons-group"
                  value={medicamentos}
                  onChange={(event) => setMedicamentos(event.target.value)}
                >
                  <FormControlLabel
                    value="si"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="Sí"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="No"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                </RadioGroup> :
                medicamentos === "si" ?
                  < p className='font-bold font-playfair'>
                    Sí
                  </p> : medicamentos === "no" ?
                    <p className='font-bold font-playfair'>
                      No
                    </p> :
                    <p className='font-bold font-playfair'>
                      ?
                    </p>
              }
            </div>
            {medicamentos === "si" &&
              <div className="text-2xsm sm:text-sm mb-2 font-utopia w-full sm:w-120 px-4 sm:px-0 flex flex-row items-center whitespace-nowrap justify-between">
                ¿ C u á l ?
                {!done ?
                  <input
                    type="text"
                    className="border w-full h-4 ml-2 bg-transparent border-b-1 border-customblack border-t-0 border-l-0 border-r-0 rounded-none focus:outline-none"
                    value={medicamentosCual}
                    onChange={(e) => setMedicamentosCual(e.target.value)}
                  />
                  :
                  <span className="w-full ml-2 font-bold font-playfair mb-1">
                    {medicamentosCual}
                  </span>
                }
              </div>
            }

            <div className="text-2xsm sm:text-sm mb-2 font-utopia w-full sm:w-120 px-4 sm:px-0 flex flex-row items-center whitespace-nowrap justify-between">
              ¿ C o n s u m e&nbsp;&nbsp;a s p i r i n a&nbsp;&nbsp;o&nbsp;&nbsp; a n t i c u a g u l a n t e s ?
              {!done ?
                <RadioGroup
                  row
                  aria-label="aspirina"
                  name="row-radio-buttons-group"
                  value={aspirina}
                  onChange={(event) => setAspirina(event.target.value)}
                >
                  <FormControlLabel
                    value="si"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="Sí"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="No"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                </RadioGroup> :
                aspirina === "si" ?
                  < p className='font-bold font-playfair'>
                    Sí
                  </p> : aspirina === "no" ?
                    <p className='font-bold font-playfair'>
                      No
                    </p> :
                    <p className='font-bold font-playfair'>
                      ?
                    </p>
              }
            </div>
            {aspirina === "si" &&
              <div className="text-2xsm sm:text-sm mb-2 font-utopia w-full sm:w-120 px-4 sm:px-0 flex flex-row items-center whitespace-nowrap justify-between">
                ¿ C u á l ?
                {!done ?
                  <input
                    type="text"
                    className="border w-full h-4 ml-2 bg-transparent border-b-1 border-customblack border-t-0 border-l-0 border-r-0 rounded-none focus:outline-none"
                    value={aspirinaCual}
                    onChange={(e) => setAspirinaCual(e.target.value)}
                  />
                  :
                  <span className="w-full ml-2 font-bold font-playfair mb-1">
                    {aspirinaCual}
                  </span>
                }
              </div>
            }

            <div className="text-2xsm sm:text-sm mb-2 font-utopia w-full sm:w-120 px-4 sm:px-0 flex flex-row items-center whitespace-nowrap justify-between">
              ¿ P a d e c e&nbsp;&nbsp; H e r p e s&nbsp;&nbsp; S i m p l e x&nbsp;&nbsp;( F u e g o&nbsp;&nbsp; l a b i a l ) ?
              {!done ?
                <RadioGroup
                  row
                  aria-label="herpesSimplex"
                  name="row-radio-buttons-group"
                  value={herpesSimplex}
                  onChange={(event) => setHerpesSimplex(event.target.value)}
                >
                  <FormControlLabel
                    value="si"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="Sí"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '1rem' }, color: 'black', '&.Mui-checked': { color: 'black' } }} />}
                    label="No"
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        fontFamily: 'Playfair, serif',
                      },
                    }}
                  />
                </RadioGroup> :
                herpesSimplex === "si" ?
                  < p className='font-bold font-playfair'>
                    Sí
                  </p> : herpesSimplex === "no" ?
                    <p className='font-bold font-playfair'>
                      No
                    </p> :
                    <p className='font-bold font-playfair'>
                      ?
                    </p>
              }
            </div>

            {/* Señale si existe alguna condición 〇 Cáncer 〇 Hepatitis 〇 Tiroides 〇 Problemas cardiacos 〇 Osteoporosis 〇 Embarazo o lactancia 〇 Diabetes 〇 Marcapasos 〇 Trombaflebitis 〇 Cicatrización Queloide 〇 Dermatitis 〇 Enfermedades Renales 〇 VIH(SIDA) 〇 Hipertensión 〇 Colesterol 〇 Inmunodeficiente 〇 Psoriasis 〇 Acné Severo 〇 Trastornos hormonales 〇 Vitiligo 〇 Hemofília 〇 Lupus */}
            <p className="text-2xl font-playfair text-customblack mt-4">
              S E Ñ A L E&nbsp;&nbsp;S I&nbsp;&nbsp;E X I S T E
            </p>
            <p className="text-2xl font-playfair text-customblack mb-4">
              A L G U N A&nbsp;&nbsp;C O N D I C I Ó N
            </p>

            {!done ? <FormGroup className="w-full sm:w-120 px-4 sm:px-0 flex justify-center items-center">
              <Grid container spacing={2} alignItems="center" justifyContent="center" className='sm:-mr-12'>
                {listaCondiciones.map((condicion, index) => (
                  <Grid item xs={6} sm={6} md={4} key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={condiciones.includes(condicion)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setCondiciones([...condiciones, condicion]);
                            } else {
                              setCondiciones(condiciones.filter((item) => item !== condicion));
                            }
                          }}
                          sx={{
                            color: 'black',
                            '&.Mui-checked': {
                              color: 'black',
                            },
                            '& .MuiSvgIcon-root': {
                              borderRadius: '50%',
                              fontSize: '1rem', // Reducir el tamaño del ícono
                            }
                          }}
                        />
                      }
                      label={condicion}
                      sx={{
                        '.MuiFormControlLabel-label': {
                          fontFamily: 'Playfair, serif',
                          fontSize: '0.75rem', // Reducir el tamaño de la etiqueta
                          lineHeight: '1.25rem',
                        }
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup> :
              <div className="mt-4">
                {condiciones.length > 0 ? (
                  <p className="font-playfair text-center flex flex-col justify-center">
                    <p className="mb-2">
                      Condiciones seleccionadas:
                    </p>
                    <span className="font-bold font-playfair h-16">
                      {condiciones.slice(0, -1).join(", ") + (condiciones.length > 1 ? " y " : "") + condiciones.slice(-1)}
                    </span>
                  </p>
                ) : (
                  <p className="font-bold font-playfair">No se han seleccionado condiciones.</p>
                )}
              </div>
            }
          </div>

          <div ref={ref3} className="flex flex-col items-center justify-center bg-eggshell">
            <p className="text-2xl font-playfair text-customblack mt-4">
              C O N T R A T O&nbsp;&nbsp;D E
            </p>
            <p className="text-2xl font-playfair text-customblack">
              C O N S E N T I M I E N T O
            </p>
            <p className="text-2xl font-playfair text-customblack mb-4">
              I N F O R M A D O
            </p>

            <p className="text-2xsm sm:text-sm mb-2 font-utopia flex flex-row items-center justify-between w-full sm:w-110 px-4 sm:px-0 italic text-justify">
              Es muy importante que usted verifique que todo el material que se utilice para hacer su tratamiento sea
              nuevo o esterilizado.
            </p>

            <div className="text-2xsm sm:text-sm mb-2 font-utopia flex flex-wrap items-center justify-between w-full sm:w-110 px-4 sm:px-0 text-justify">
              <p>
                Yo:
                {!done ?
                  <input
                    type="text"
                    className="border w-52 h-4 ml-2 bg-transparent border-b-1 border-customblack border-t-0 border-l-0 border-r-0 rounded-none focus:outline-none mx-2"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  /> :
                  <span className="font-bold font-playfair mx-2">{name}</span>
                }
                por mi derecho autorizo que se me realicen los tratamientos de mi elección, certifico que me han sido completamente explicados, así como los alcances, diagnósticos y resultados; la posibilidad y naturaleza de probables complicaciones o reacciones no pueden ser anticipadas, por lo tanto no puede haber una garantía de que no se presentaran tomando en cuenta que todos los organismos reaccionan de forma diferente y el resultado del tratamiento dependerá de mi cuerpo y la reacción de mi organismo.
              </p>
            </div>

            <p className="text-2xsm sm:text-sm mb-2 font-utopia flex flex-row items-center justify-between w-full sm:w-110 px-4 sm:px-0 text-justify">
              Certifico que he analizado en su totalidad el tratamiento, los riesgos, beneficios, el
              cuidado posterior y las expectativas de resultados, que lo entiendo completamente todo
              y que se me ha dado una amplia oportunidad para la discusión y todas mis preguntas
              han sido respondidas satisfactoriamente. Soy consciente de que existen riesgos
              inherentes a todo procedimiento de este tipo de tratamientos que aunque poco
              frecuentes puede presentarse (infecciones, reacciones alérgicas, cicatrices patológicas,
              inflamaciones, hematomas, oclusiones vasculares, entre otras). Por la presente doy mi
              consentimiento al procedimiento. Esto constituye una divulgación completa y reemplaza
              cualquier divulgación escrita.
            </p>

            <p className="text-2xsm sm:text-sm mb-2 font-utopia flex flex-row items-center justify-between w-full sm:w-110 px-4 sm:px-0 text-justify">
              Doy mi consentimiento para que se tomen fotografías ANTES, DURANTE y DESPUÉS
              de mi procedimiento, Estoy de acuerdo con que estos sean almacenados con
              confidencialidad y solo utilizados con consentimiento para fines promocionales.
            </p>

            <p className="text-2xsm sm:text-sm font-utopia flex flex-row items-center justify-between w-full sm:w-110 px-4 sm:px-0 mb-16 text-justify">
              Manifiesto bajo protesta que declaro que lo aquí informado es absolutamente cierto,
              que no oculto información que pudiera ser importante para el resultado de mi
              tratamiento y que me encuentro fisicamente apta para recibir cualquier tratamiento, por
              lo que libero de toda responsabilidad presente y futura a Melany Lin Marrujo, Así mismo
              estoy de acuerdo que el pago de cualquier servicio realizado no es reembolsable.
            </p>

            {signature && <img src={signature} alt="Firma" className="w-32" />}

            {!signature && <div className="w-32 h-16" onClick={startSigning}> </div>}
            <hr className="h-0.7 w-36 bg-customblack" onClick={startSigning} />
            <p className="w-36 text-center font-utopia text-2xsm sm:text-sm mb-4">
              Firma
            </p>
          </div>
          <button
            onClick={startSigning}
            className="font-playfair mb-8 flex flex-row items-center justify-center border-2 border-customblack px-2 py-1 rounded-md hover:bg-egghselldarker"
          >
            <Edit className="mr-2" style={{ fontSize: '1.0rem' }} />
            {signature ? "Firmar de nuevo" : "Firmar"}
          </button>
          {!done ?
            <button
              onClick={() => {
                if (!signature) {
                  // Muestra SweetAlert si no hay firma
                  swal({
                    title: "Firma Requerida",
                    text: "Es necesario firmar el contrato de consentimiento informado antes de enviar el formulario.",
                    icon: "error", // Cambia el ícono a 'error'
                    buttons: {
                      cancel: {
                        text: "Cancelar",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                      },
                      confirm: {
                        text: "Firmar ahora",
                        value: true,
                        visible: true,
                        className: "btn-primary", // Asume que tienes una clase 'btn-primary' para estilos
                        closeModal: true
                      }
                    },
                    dangerMode: true,
                  }).then((willSign) => {
                    if (willSign) {
                      startSigning();
                    }
                  });
                } else if (!name || !fecha || !fechaNacimiento || !edad || !comoTeEnteraste || !sensibleAlDolor || !fumasObebes || !alergiasMedicamento || !alergiasCosmeticos || !cuidadosMedicos || !medicamentos || !aspirina || !herpesSimplex) {
                  // Muestra SweetAlert si hay campos vacíos
                  swal({
                    title: "Campos Incompletos",
                    text: "Por favor, complete todos los campos del formulario antes de enviarlo.",
                    icon: "error", // Cambia el ícono a 'error'
                    buttons: {
                      cancel: {
                        text: "Cancelar",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                      },
                      confirm: {
                        text: "Completar ahora",
                        value: true,
                        visible: true,
                        className: "btn-primary", // Asume que tienes una clase 'btn-primary' para estilos
                        closeModal: true
                      }
                    },
                    dangerMode: true,
                  });
                } else {
                  setDone(true);
                  generatePDF();
                }
              }}

              className="font-playfair font-bold mb-8 flex flex-row items-center justify-center border-2 border-customblack px-2 py-1 rounded-md hover:bg-egghselldarker"
            >
              <Send className="mr-2" style={{ fontSize: '1.0rem', rotate: '-45deg' }} />
              Enviar formulario
            </button> : !sending ?
              <div className="text-center mb-4 flex flex-col items-center justify-center">
                <button
                  onClick={() => {
                    setDone(false);
                  }}
                  className="font-playfair font-bold mb-4 flex flex-row items-center justify-center border-2 border-customblack px-2 py-1 rounded-md hover:bg-egghselldarker"
                >
                  <Edit className="mr-2" style={{ fontSize: '1.0rem' }} />
                  Editar formulario
                </button>
                <button
                  onClick={() => {
                    setDone(false);
                    setSignature(null);
                    setCondiciones([]);
                    setName("");
                    setFechaNacimiento("");
                    setEdad("");
                    setComoTeEnteraste("");
                    setSensibleAlDolor("");
                    setFumasObebes("");
                    setAlergiasMedicamento("");
                    setAlergiasMedicamentoCual("");
                    setAlergiasCosmeticos("");
                    setAlergiasCosmeticosCual("");
                    setCuidadosMedicos("");
                    setCuidadosMedicosCual("");
                    setMedicamentos("");
                    setMedicamentosCual("");
                    setAspirina("");
                    setAspirinaCual("");
                    setHerpesSimplex("");
                  }}
                  className="font-playfair font-bold mb-4 flex flex-row items-center justify-center border-2 border-customblack px-2 py-1 rounded-md hover:bg-egghselldarker"
                >
                  <Delete className="mr-2" style={{ fontSize: '1.0rem' }} />
                  Vaciar formulario
                </button>

              </div>

              :
              <div className="text-center mb-4 flex flex-col items-center justify-center">
                <p className="font-playfair mb-4">
                  Enviando formulario...
                </p>
                <CircularProgress color="inherit" size={20} className='mb-8' />
              </div>
          }
        </>
      )
      }
    </div >
  );
}

export default App;
