import { getFunctions } from 'firebase/functions';
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCamt53dValYxZtMtsUsuaOrM8kLC0NktY",
    authDomain: "melanylin-cffe7.firebaseapp.com",
    projectId: "melanylin-cffe7",
    storageBucket: "melanylin-cffe7.appspot.com",
    messagingSenderId: "871625327212",
    appId: "1:871625327212:web:af0db23841d24288510e49",
    measurementId: "G-0BL450QD9Y"
};
// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Get the Firebase functions instance
const functions = getFunctions(app);

const storage = getStorage(app);

export { functions, storage };